// UserStatusIndicator.js
import { connect } from 'react-redux';

import UserStatusIndicator from '../component/UserStatusIndicator.jsx';

import getUserData from '../selector/getUserData.js';
import getLiveStreamData from '../selector/getLiveStreamData.js';
import toggleSubscribeOnlineStatus from '../action/toggleSubscribeOnlineStatus.js';

const mapStateToProps = (state, { userId }) => {
  const isOnline = userId ? getUserData(state, userId, 'isOnline') : false;
  return {
    isOnline,
    isLiveStreaming: getLiveStreamData(state, [userId], 'isLiveStreaming'),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleSubscribeOnlineStatus: ({
      userId,
      shouldSubscribe,
      subscriptionReference,
    }) =>
      dispatch(
        toggleSubscribeOnlineStatus({
          userId,
          shouldSubscribe,
          subscriptionReference,
        })
      ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserStatusIndicator);
