// toggleSubscribeOnlineStatus.js
'use strict';
import { updatePusherChannelTarget } from '../resource/pusherUtils.js';

/**
 * Toggle subscribe online status
 * @kind action
 * @param {string} {userId} - user id.
 * @param {bool} {shouldSubscribe} - should subscribe or unsubscribe
 * @param {bool} {shouldRetry} - should retry to subscribe channel.
 * @param {string} {subscriptionReference} - subscription reference for identify subscribe reason and source
 * @return {Promise} Action promise.
 */
const toggleSubscribeOnlineStatus =
  ({ userId, shouldSubscribe, shouldRetry, subscriptionReference }) =>
  async dispatch => {
    updatePusherChannelTarget({
      target: {
        [`private-enc-user@${userId}`]: {
          shouldSubscribe,
          subscriptionReference,
          isCritical: shouldRetry,
        },
      },
    });
    return dispatch({ type: '' });
  };

export default toggleSubscribeOnlineStatus;
