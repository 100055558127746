// UserStatusIndicator.jsx
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import WithIntersectionObserver from '../component/WithIntersectionObserver.jsx';

import LiveStreamBadge from '../container/LiveStreamBadge.js';

import { LIVESTREAM_TYPE_DOT } from '../resource/userStatusIndicatorConstants.js';
import { NULL_FUNCTION } from '../resource/defaults.js';
import { color, DotSize } from '../style/variables.js';

export class UserStatusIndicator extends React.PureComponent {
  subscriptionReference = `UserStatusIndicator-${uuidv4()}`;
  nextTick = null;
  state = {
    isIntersecting: false,
  };

  handleIntersection = ({ isIntersecting }) => {
    this.setState({ isIntersecting });
  };

  toggleSubscribe = ({ userId, shouldSubscribe }) => {
    const {
      toggleSubscribeOnlineStatus,
      shouldShowLiveStream,
      shouldShowOnline,
    } = this.props;
    const { isIntersecting } = this.state;
    if (userId) {
      toggleSubscribeOnlineStatus({
        userId,
        shouldSubscribe:
          (shouldShowLiveStream || shouldShowOnline) &&
          shouldSubscribe &&
          isIntersecting,
        subscriptionReference: this.subscriptionReference,
      });
    }
  };

  componentDidMount() {
    this.nextTick = setTimeout(() => {
      const { userId } = this.props;
      this.toggleSubscribe({ userId, shouldSubscribe: true });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { userId, shouldShowLiveStream, shouldShowOnline } = this.props;
    const { isIntersecting } = this.state;
    const {
      userId: prevUserId,
      shouldShowLiveStream: prevShouldShowLiveStream,
      shouldShowOnline: prevShouldShowOnline,
    } = prevProps;
    const { isIntersecting: prevIsIntersecting } = prevState;

    if (
      userId !== prevUserId ||
      isIntersecting !== prevIsIntersecting ||
      shouldShowLiveStream !== prevShouldShowLiveStream ||
      shouldShowOnline !== prevShouldShowOnline
    ) {
      clearTimeout(this.nextTick);
      if (userId !== prevUserId) {
        this.toggleSubscribe({ userId: prevUserId, shouldSubscribe: false });
      }
      this.nextTick = setTimeout(() => {
        this.toggleSubscribe({ userId, shouldSubscribe: true });
      });
    }
  }

  componentWillUnmount() {
    const { userId } = this.props;
    clearTimeout(this.nextTick);
    this.toggleSubscribe({ userId, shouldSubscribe: false });
  }

  render() {
    const {
      userId,
      isLiveStreaming,
      isOnline,
      shouldShowLiveStream,
      liveStreamType,
      livestreamSize,
      dotSize,
      shouldShowOnline,
      borderSize,
    } = this.props;

    let Component = <div />;

    if (userId) {
      if (isLiveStreaming && shouldShowLiveStream) {
        Component = (
          <LiveStreamBadge
            streamId={userId}
            userId={userId}
            type={liveStreamType}
            size={livestreamSize}
          />
        );
      } else if (shouldShowOnline && isOnline) {
        Component = (
          <OnlineIndicator dotSize={dotSize} borderSize={borderSize} />
        );
      }
    }

    return (
      <WithIntersectionObserver
        threshold={0.25}
        onChange={this.handleIntersection}
        shouldKeepObserve
      >
        {() => Component}
      </WithIntersectionObserver>
    );
  }
}

UserStatusIndicator.propTypes = {
  userId: PropTypes.string,
  liveStreamType: PropTypes.string,
  livestreamSize: PropTypes.number,
  dotSize: PropTypes.number,
  isLiveStreaming: PropTypes.bool,
  isOnline: PropTypes.bool,
  shouldShowLiveStream: PropTypes.bool,
  shouldShowOnline: PropTypes.bool,
  toggleSubscribeOnlineStatus: PropTypes.func,
  borderSize: PropTypes.number,
};

UserStatusIndicator.defaultProps = {
  userId: null,
  liveStreamType: LIVESTREAM_TYPE_DOT,
  livestreamSize: 18,
  dotSize: DotSize.SIZE_8,
  isLiveStreaming: null,
  isOnline: false,
  shouldShowLiveStream: false,
  shouldShowOnline: false,
  toggleSubscribeOnlineStatus: NULL_FUNCTION,
  borderSize: 0,
};

const getBorder = borderSize => {
  return borderSize > 0 ? `${borderSize}px solid ${color.grey['900']}` : 'none';
};

const OnlineIndicator = styled.div.attrs(({ dotSize, borderSize }) => ({
  style: {
    width: `${dotSize}px`,
    height: `${dotSize}px`,
    border: getBorder(borderSize),
  },
}))`
  border-radius: 50%;
  background-color: ${color.lime};
`;

export default UserStatusIndicator;
